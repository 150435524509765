<script>
import useVuelidate from '@vuelidate/core'
import Layout from '../../layouts/main.vue'
import PageHeader from '@/components/page-header'
import Swal from 'sweetalert2'


export default {
  setup () {
    return { v$: useVuelidate() }
  },

  data () {
    return {
      page: 1,
      perPage: 10,
      pages: [],
      submited: false,
      spanView: false,
      data: {},
      classes: [],
      classe: {},
      title: 'Gestion ecolage',
      items: [
        {
          text: 'Ecolages',
          href: '/'
        },
        {
          text: 'Créer',
          active: true
        }
      ]
    }
  },

  components: {
    Layout,
    PageHeader,
  },
  mounted () {
    this.getClasses()
  },
  computed: {
    displayedPosts() {
      return this.paginate(this.classes);
    },
  },
  methods: {
    initFacultyCreation () {
      this.submited = true;
      this.createFaculty();
    },

    getClasses () {
      const self = this
      this.$store
        .dispatch('getRequest', {
          route: 'api/classe ',
          data: this.data
        })
        .then(
          function (response) {
          self.classes = response.data;
          self.setPages();
          },
          function (error) {
            console.log(error)
          }
        )
    },
    initEcolageUpdate() {
      this.spanView = true;
      const self = this;
      this.$store
        .dispatch('putRequest', { route: `api/classe/${this.classe.id}`, data: this.classe })
        .then(
          function (response) {
            self.spanView = false
            self.getClasses()
            Swal.fire(response.data.message,'Modification éffectuer avec succès!','success')
          },
          function (error) {
            if (error !== null) {
              self.spanView = false
              Swal.fire({
                title: 'Oops...',
                text: 'Il y a un problème!',
                icon: 'error',
                confirmButtonClass: 'btn btn-primary w-xs mt-2',
                buttonsStyling: false,
                showCloseButton: true
              })
            }
          }
        )
    },
    getClasse(classe){
      this.classe = classe;
    },
    setPages () {
      let numberOfPages = Math.ceil(this.classes.length / this.perPage)
      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index)
      }
    },
    paginate (data) {
      let page = this.page
      let perPage = this.perPage
      let from = page * perPage - perPage
      let to = page * perPage
      return data.slice(from, to)
    }
  }
}
</script>
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="col-md-12">
      <div class="card">
        <div class="card-body p-4">
          <div class="table-responsive table-card">
            <table class="table align-middle table-nowrap" id="customerTable">
              <thead class="table-light text-muted">
                <tr>
                  <th class="sort" scope="col" data-sort="currency_name">Id</th>

                  <th class="sort" scope="col" data-sort="currency_name">
                    Classe
                  </th>

                  <th class="sort" scope="col" data-sort="currency_name">
                    Niveau
                  </th>

                  <th class="sort" scope="col" data-sort="currency_name">
                    Ecolage
                  </th>

                  <th class="sort" scope="col" data-sort="currency_name">
                    Action
                  </th>
                </tr>
              </thead>
              <!--end thead-->
              <tbody class="list form-check-all">
                <tr v-for="classe in displayedPosts" :key="classe.id">
                  <th scope="row">{{ classe.id }}</th>
                  
                  <td>{{ classe.name }} </td>

                  <td>{{ classe.level }} </td>

                  <td>{{ classe.ecolage }}</td>

                  <td>
                    <div class="hstack gap-3 flex-wrap">
                      <a href="javascript:void(0)" 
                        data-bs-toggle="modal"
                        data-bs-target=".bs-faculty-update"
                        @click="getClasse(classe)" class="link-primary fs-15">
                        <i class="ri-edit-2-line"></i>
                      </a>
                      <a class="link-success fs-15"
                       href="javascript:void(0)" 
                       @click="this.$router.push({ path: `/tranches/${classe.id}` })">
                        <i class="ri-eye-line"></i>
                      </a>
                    </div>
                  </td>
                </tr>
              </tbody>
              <!--end tbody-->
            </table>
          </div>
          <div class="d-flex justify-content-end mt-3">
            <div class="pagination-wrap hstack gap-2">
              <a
                class="page-item pagination-prev disabled"
                href="#"
                v-if="page != 1"
                @click="page--"
              >
                Previous
              </a>
              <ul class="pagination listjs-pagination mb-0">
                <li
                  :class="{
                    active: pageNumber == page,
                    disabled: pageNumber == '...'
                  }"
                  v-for="(pageNumber, index) in pages.slice(page - 1, page + 5)"
                  :key="index"
                  @click="page = pageNumber"
                >
                  <a class="page" href="#">{{ pageNumber }}</a>
                </li>
              </ul>
              <a
                class="page-item pagination-next"
                href="#"
                @click="page++"
                v-if="page < pages.length"
              >
                Next
              </a>
            </div>
          </div>
        </div>
        <div
          class="modal fade bs-faculty-update"
          tabindex="-1"
          role="dialog"
          aria-labelledby="updateFacultyModal"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-lg">
            <form @submit.prevent="initEcolageUpdate">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="updateFacultyModal">Modifier</h5>
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div class="modal-body">
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="mb-3">
                        <label for="classeLevel" class="form-label" >Classe</label >
                        <input
                          type="text"
                          class="form-control"
                          id="classeLevel"
                          placeholder="Classe"
                          v-model="classe.name"
                          readonly
                        />
                      </div>
                    </div>
                    <!--end col-->
                    <div class="col-lg-6">
                      <div class="mb-3">
                        <label for="level" class="form-label"
                          >Niveau</label
                        >
                        <input
                          type="text"
                          class="form-control"
                          id="ecolage"
                          placeholder="Ecolage"
                          v-model="classe.level"
                          readonly
                        />
                      </div>
                    </div>
                    <!--end col-->
                    <div class="col-lg-12">
                      <div class="mb-3">
                        <label for="ecolage" class="form-label"
                          >Ecolage</label
                        >
                        <input
                          type="number"
                          class="form-control"
                          id="ecolage"
                          placeholder="Ecolage"
                          v-model="classe.ecolage"
                        />
                      </div>
                    </div>
                  </div>
                  <!--end row-->
                </div>
                <div class="modal-footer">
                  <a
                    href="javascript:void(0);"
                    class="btn btn-link link-success fw-medium"
                    data-bs-dismiss="modal"
                  >
                    <i class="ri-close-line me-1 align-middle"></i> Close
                  </a>
                  <button
                    type="submit"
                    class="btn btn-primary"
                    :disabled="spanView"
                  >
                    <span
                      v-if="spanView"
                      class="spinner-border spinner-border-sm"
                    ></span>
                    Confirmer
                  </button>
                </div>
              </div>
            </form>
            <!-- /.modal-content -->
          </div>
          <!-- /.modal-dialog -->
        </div>
      </div>
    </div>
  </Layout>
</template>
